import React from "react";
import CompanyInfo from "../../components/CompanyInfo/CompanyInfo";
import MainControls from "../../components/MainControls/MainControls";
import Logout from "../../components/Logout/Logout";
import useCompanyLogo from "../../hooks/useCompanyLogo";
import Loading from "../../components/Loading/Loading";
import useCurrentUser from "../../hooks/useCurrentUser";

const MainApp = () => {
  const logo = useCompanyLogo();
  const { user, loading } = useCurrentUser();

  return loading ? (
    <Loading loading={true} content="Loading your options..." />
  ) : (
    <>
      <CompanyInfo logo={logo ?? `${process.env.PUBLIC_URL}/logos/pcs.png`} />
      <Logout />
      <MainControls urnHolder={user?.organisation?.reference !== "RCP"} />
    </>
  );
};

export default MainApp;

import React, { useState } from "react";
import { Form, Header, Modal } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { LOGIN, REGISTER } from "../../graphql/auth";
import { useMutation } from "@apollo/react-hooks";
import loggedIn from "../../util/loggedIn";
import Login from "../../components/Login/Login";
import Register from "../../components/Register/Register";
import styled from "styled-components";
import checkEmptyValues from "../../util/checkEmptyValues";
import { EMAIL_REGEX } from "../../regex/auth";
import Loading from "../../components/Loading/Loading";
import CustomModal from "../../components/Modal/Modal";

const AuthContainer = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 10px;
  box-shadow: 0px 0px 3px white;
  border-radius: 5px;
  overflow: hidden;
  min-width: 400px;
  min-height: 400px;

  .ui.form .field > label {
    color: white;
  }
`;

const AuthSubmitContainer = styled(Form.Field)`
  display: flex;
  justify-content: center;
`;

const AuthForm = styled(Form)`
  width: 100%;
  margin-bottom: 50px;
`;

const ErrorModalHeader = styled(Modal.Header)`
  color: red !important;
`;

const Auth = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [thirdPartyId, setThirdPartyId] = useState();
  const [title, setTitle] = useState("");
  const [register, setRegister] = useState(false);
  const [email, setEmail] = useState("");
  const [organisation, setOrganisation] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const [login, { loading }] = useMutation(LOGIN);
  const [registerUser, registerData] = useMutation(REGISTER);
  const message = `Unexpected error, please try again later`;

  const onLoginHandler = async () => {
    setErrorMessage();
    if (username === "") {
      setErrorMessage("Please provide a username.");
      return setModal(true);
    }
    try {
      const loginData = await login({
        variables: {
          username: username,
          password: password,
          artworkUser: true,
        },
      });
      if (loginData.data && loginData.data.login) {
        if (loginData.data.login.success && loginData.data.login.user.token) {
          loggedIn(loginData.data.login.user.token);
          history.push("/dashboard");
        } else if (loginData.data.login.message !== errorMessage) {
          setModal(true);
          setErrorMessage(loginData.data.login.message);
        }
      }
    } catch (err) {
      setModal(true);
      setErrorMessage(message);
    }
  };

  const registrationValidation = () => {
    if (password !== confirmPassword)
      return {
        success: false,
        message: "Please make sure your passwords match.",
      };
    if (!EMAIL_REGEX.test(email))
      return { success: false, message: "Please use a valid email address." };
    return { success: true };
  };

  const onSubmitRegisterHandler = async () => {
    setErrorMessage();
    const empty = checkEmptyValues(
      title,
      firstName,
      surname,
      username,
      email,
      password,
      confirmPassword,
      organisation
    );
    if (empty) {
      setErrorMessage("Make sure there are no empty values.");
      return setModal(true);
    }
    const validated = registrationValidation();
    if (!validated.success) {
      setErrorMessage(validated.message);
      return setModal(true);
    }
    try {
      const registerUserData = await registerUser({
        variables: {
          user: {
            username: username,
            title: title,
            firstname: firstName,
            surname: surname,
            email: email,
            password: password,
            thirdPartyId,
            artworkUser: true,
            organisation,
          },
        },
      });

      if (registerUserData.data && registerUserData.data.register) {
        if (
          registerUserData.data.register.success &&
          registerUserData.data.register.user.token
        ) {
          loggedIn(registerUserData.data.register.user.token);
          history.push("/dashboard");
        } else if (registerUserData.data.register.message !== errorMessage) {
          setErrorMessage(registerUserData.data.register.message);
          setModal(true);
        }
      }
    } catch (err) {
      setModal(true);
      setErrorMessage(message);
    }
  };

  const resetState = () => {
    setRegister((state) => !state);
    setUsername("");
    setPassword("");
    setErrorMessage();
    setFirstName("");
    setSurname("");
    setEmail("");
    setConfirmPassword("");
    setThirdPartyId("");
  };

  return (
    <>
      <AuthContainer>
        <Header textAlign="center" inverted size="huge">
          Artwork Upload
        </Header>
        <AuthForm
          onSubmit={(e) => {
            e.preventDefault();
            if (!register) {
              onLoginHandler();
            } else {
              onSubmitRegisterHandler();
            }
          }}
        >
          <Form.Field>
            {register ? (
              <Register
                username={username}
                onChangeUsername={setUsername}
                password={password}
                onChangePassword={setPassword}
                email={email}
                onChangeEmail={setEmail}
                confirmPassword={confirmPassword}
                onChangeConfirmPassword={setConfirmPassword}
                thirdPartyId={thirdPartyId}
                onChangeThirdPartyId={setThirdPartyId}
                firstName={firstName}
                onChangeFirstName={setFirstName}
                surname={surname}
                onChangeSurname={setSurname}
                title={title}
                onChangeTitle={setTitle}
                organisation={organisation}
                onChangeOrganisation={setOrganisation}
              />
            ) : (
              <Login
                username={username}
                onChangeUsername={setUsername}
                password={password}
                onChangePassword={setPassword}
              />
            )}
          </Form.Field>
          <AuthSubmitContainer>
            <Form.Button primary type="submit">
              {register ? "Submit" : "Login"}
            </Form.Button>
            <Form.Button onClick={() => resetState()} secondary type="button">
              {register ? "Cancel" : "Register"}
            </Form.Button>
          </AuthSubmitContainer>
        </AuthForm>
        <Loading
          content={register ? "ATTEMPTING REGISTER..." : "ATTEMPTING LOGIN..."}
          loading={loading || registerData.loading}
        />
        {errorMessage ? <Modal /> : null}
        <CustomModal
          color="red"
          clearMessage={() => setErrorMessage()}
          header={<ErrorModalHeader>Error</ErrorModalHeader>}
          message={errorMessage}
          modalState={modal}
          setModal={setModal}
        />
      </AuthContainer>
    </>
  );
};

export default Auth;

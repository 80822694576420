import React, { useState } from "react";
import { Form, Header } from "semantic-ui-react";
import { GET_PUBLICATIONS, GET_PUB_DATES } from "../../urls/publication";
import DatePicker from "react-datepicker";
import useAxios from "axios-hooks";
import "react-datepicker/dist/react-datepicker.css";

const PublicationHolder = ({
  publication,
  onChangePublication,
  insertDate,
  onChangeInsertDate,
  cms,
  onChangeCms,
  cols,
  onChangeCols,
  adType,
  onChangeAdType,
}) => {
  const [publicationOptions, setPublicationOptions] = useState([]);
  const [pubDates, setPubDates] = useState([]);
  const pubDatesUrl = GET_PUB_DATES(publication);
  const [{ data }] = useAxios(pubDatesUrl);
  const [{ data: response, loading }] = useAxios(GET_PUBLICATIONS);

  const formatEntries = () => {
    return data.Entries.map((dateArray) => new Date(dateArray[1]));
  };

  if (data) {
    const formattedEntries = formatEntries();
    const areArraysTheSame = pubDates.every(
      (value, index) => value?.getTime() === formattedEntries[index]?.getTime()
    );
    if (!areArraysTheSame || formattedEntries.length > pubDates.length) {
      setPubDates(formattedEntries);
    }
  }

  const formatPublicationOptions = () => {
    return response.Entries.map((pubArray) => ({
      key: pubArray[0],
      text: pubArray[0],
      value: pubArray[0],
    }));
  };

  if (response && !loading && publicationOptions.length === 0) {
    const pubOptions = formatPublicationOptions();
    setPublicationOptions(pubOptions);
  }

  return (
    <>
      <Form.Group>
        <Form.Field>
          <Header inverted>Advert Type</Header>
        </Form.Field>
        <Form.Radio
          checked={adType === "print"}
          label="Print"
          onChange={() => onChangeAdType("print")}
        />
        <Form.Radio
          checked={adType === "ipad"}
          label="iPad"
          onChange={() => onChangeAdType("ipad")}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <Form.Select
            onChange={(_, { value }) => {
              onChangePublication(value);
            }}
            label="Publication"
            options={publicationOptions}
            value={publication ?? ""}
            clearable
          />
          <label>Publication Date</label>
          <DatePicker
            selected={insertDate}
            includeDates={pubDates}
            disabled={pubDates.length === 0}
            onChange={(date) => onChangeInsertDate(date)}
            dateFormat="dd/MM/yyyy"
          />
          <Header size="small" inverted>
            Advert Size
          </Header>
          <Form.Input
            type="number"
            label="Cms"
            value={cms ?? ""}
            onChange={(e) => onChangeCms(e.target.value)}
          />
          <Form.Input
            type="number"
            label="Cols"
            value={cols ?? ""}
            onChange={(e) => onChangeCols(e.target.value)}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default PublicationHolder;

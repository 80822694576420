import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Loader, Header, Placeholder } from "semantic-ui-react";
import styled from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledDocument = styled(Document)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  min-height: 400px;
  width: 60%;

  .react-pdf__message--no-data {
    width: 80%;
    height: 80%;
  }
`;

const DocumentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPage = styled(Page)`
  box-shadow: 0px 0px 3px black;
`;

const StyledHeader = styled(Header)`
  color: white !important;
`;

const NewFileHolder = (props) => {
  return (
    <>
      <input
        type="file"
        label="New File Upload"
        onChange={(e) => {
          const file = e.target.files[0];
          props.setImageType(file ? file.type : undefined);
          props.setFileUrl(file ? URL.createObjectURL(file) : undefined);
          props.onUpload(file);
        }}
        disabled={!props.enabled}
        accept="application/pdf,image/jpeg,image/jpg,image/png"
      />
      <StyledHeader>Upload Preview</StyledHeader>
      <DocumentContainer>
        {props.imageType === "application/pdf" ? (
          <StyledDocument
            file={props.fileUrl}
            error="Ooops we couldn't display that document."
            options={{ withCredentials: true }}
            noData={
              <Placeholder fluid>
                <Placeholder.Image square />
              </Placeholder>
            }
          >
            <StyledPage
              loading={<Loader active={true} />}
              width={250}
              pageNumber={1}
            />
          </StyledDocument>
        ) : (
          <img src={props.fileUrl} />
        )}
      </DocumentContainer>
    </>
  );
};

export default React.memo(NewFileHolder);

import gql from "graphql-tag";

export const CHECK_URN = gql`
  query GetUrn($urn: String!) {
    getJobByUrn(urn: $urn) {
      success
      job {
        urn
      }
    }
  }
`;

export const UPLOAD_ARTWORK = gql`
  mutation UploadArtwork($file: Upload!, $input: UploadArtworkInput!) {
    uploadArtwork(file: $file, input: $input) {
      name
    }
  }
`;

export const GET_CURRENT_USER_ARTWORK = gql`
  query GetCurrentUserArtwork {
    getUploadedArtworkForCurrentUser {
      _id
      name
      previewUrl
      uploadedAt
    }
  }
`;

export const UPLOAD_PREVIOUS_ARTWORK = gql`
  mutation UploadPreviousArtwork($input: PreviousArtworkUploadMutationInput!) {
    uploadPreviousArtwork(input: $input) {
      name
    }
  }
`;

export const GET_CURRENT_USER = gql`
  {
    currentUser {
      _id
      organisation {
        _id
        reference
        logo
      }
    }
  }
`;

export const GET_ORGANISATIONS = gql`
  query GetOrganisations {
    getOrganisations {
      _id
      name
    }
  }
`;

import React, { useState } from "react";
import { Form, Modal, Button } from "semantic-ui-react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  CHECK_URN,
  UPLOAD_ARTWORK,
  GET_CURRENT_USER_ARTWORK,
  UPLOAD_PREVIOUS_ARTWORK,
} from "../../graphql/mainControls";
import Loading from "../Loading/Loading";
import CustomModal from "../Modal/Modal";
import MainControlLeft from "./components/MainControlLeft";
import MainControlRight from "./components/MainControlRight";

const MainControlsContainer = styled.div`
  display: flex;
  grid-area: main;
  justify-content: center;
  overflow: auto;

  .field {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
`;

const MainControlsSubContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
`;

const MainControlForm = styled(Form)`
  max-height: 100%;
  color: white !important;
  .ui.form textarea {
    resize: none;
  }
  width: 100%;
  margin: 30px;
`;

const ModalErrorHeader = styled(Modal.Header)`
  color: red !important;
`;

const ModalSuccessHeader = styled(Modal.Header)`
  color: green !important;
`;

const MainControls = ({ urnHolder }) => {
  const PREVIOUS_FILE_STATE = "previous";
  const NEW_FILE_STATE = "new";
  const [urnId, setUrnId] = useState("");
  const [instructions, setInstructions] = useState("");
  const [mono, setMono] = useState(false);
  const [fileUpload, setFileUpload] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [fileState, setFileState] = useState(NEW_FILE_STATE);
  const [adType, setAdType] = useState("print");
  const [cms, setCms] = useState();
  const [cols, setCols] = useState();
  const [publication, setPublication] = useState();
  const [date, setDate] = useState();
  const [modalState, setModalState] = useState();
  const [imageType, setImageType] = useState();
  const checkUrnQuery = useQuery(CHECK_URN, {
    variables: { urn: urnId },
  });
  const previousUploadQuery = useQuery(GET_CURRENT_USER_ARTWORK);
  const [uploadArtwork, { loading }] = useMutation(UPLOAD_ARTWORK, {
    refetchQueries: [{ query: GET_CURRENT_USER_ARTWORK }],
    awaitRefetchQueries: true,
  });
  const [uploadPreviousArtwork, uploadPreviousArtworkData] = useMutation(
    UPLOAD_PREVIOUS_ARTWORK,
    {
      refetchQueries: [{ query: GET_CURRENT_USER_ARTWORK }],
      awaitRefetchQueries: true,
    }
  );
  const [modal, setModal] = useState(false);
  const [oldFileSelected, setOldFileSelected] = useState();

  const previousUploads =
    previousUploadQuery.data?.getUploadedArtworkForCurrentUser || [];

  const resetState = () => {
    if (document.querySelector("input[type=file]")) {
      document.querySelector("input[type=file]").value = null;
    }
    setUrnId("");
    setInstructions("");
    setMono(false);
    setFileUpload();
    setAdType("print");
    setCms();
    setCols();
    setPublication();
    setDate();
    setFileUrl();
    setOldFileSelected();
  };

  const submitValidation = () => {
    if (!checkUrnQuery.data.getJobByUrn.success && urnHolder)
      return { success: false, message: "Please enter a valid urn." };
    if (!fileUpload && !oldFileSelected)
      return { success: false, message: "Please pick a file to associate." };
    return { success: true };
  };

  const somethingWentWrongError = () => {
    setModalState({
      header: <ModalErrorHeader>Error</ModalErrorHeader>,
      message: "Ooops something went wrong. Try again later.",
      color: "red",
    });
    return setModal(true);
  };

  const formatInstructions = () => {
    return instructions.replace(/\n/g, " ");
  };

  const uploadNewArtworkHandler = async () => {
    try {
      const formattedInstructions = formatInstructions();
      const uploadArtworkData = await uploadArtwork({
        variables: {
          file: fileUpload,
          input: {
            urn: urnId,
            instruction: formattedInstructions,
            pubName: publication ?? "",
            cms: Number(cms) ?? 1,
            cols: Number(cols) ?? 1,
            insertDate: date ?? new Date(),
            adType,
          },
        },
      });
      if (uploadArtworkData.data.uploadArtwork.name) {
        resetState();
        setModalState({
          header: <ModalSuccessHeader>Success</ModalSuccessHeader>,
          message: "Artwork successfully uploaded.",
          color: "green",
        });
        return setModal(true);
      }
    } catch (err) {
      if (err) somethingWentWrongError();
    }
  };

  const uploadOldArtworkHandler = async () => {
    try {
      const formattedInstructions = formatInstructions();
      const uploadOldArtworkData = await uploadPreviousArtwork({
        variables: {
          input: {
            id: oldFileSelected,
            urn: urnId,
            instruction: formattedInstructions,
            pubName: publication ?? "",
            cms: Number(cms) ?? 1,
            cols: Number(cols) ?? 1,
            insertDate: date ?? new Date(),
            adType,
          },
        },
      });
      if (uploadOldArtworkData.data.uploadPreviousArtwork.name) {
        resetState();
        setModalState({
          header: <ModalSuccessHeader>Success</ModalSuccessHeader>,
          message: "Artwork successfully uploaded.",
          color: "green",
        });
        return setModal(true);
      }
    } catch (err) {
      if (err) somethingWentWrongError();
    }
  };

  const onSubmitHandler = async () => {
    setModalState();
    if (fileState === NEW_FILE_STATE) {
      await uploadNewArtworkHandler();
    } else {
      await uploadOldArtworkHandler();
    }
  };

  return (
    <MainControlsContainer>
      <MainControlForm
        onSubmit={() => {
          const validate = submitValidation();
          if (!validate.success) {
            setModalState({
              header: <ModalErrorHeader>Error</ModalErrorHeader>,
              message: validate.message,
              color: "red",
            });
            return setModal(true);
          }
          setModal(true);
          setModalState({
            header: (
              <ModalSuccessHeader>Terms and Condtions</ModalSuccessHeader>
            ),
            message: "Do you agree to the terms and conditions?",
            color: "green",
            buttons: (
              <>
                <Button
                  primary
                  onClick={() => {
                    setModal(false);
                    onSubmitHandler();
                  }}
                >
                  Yes
                </Button>
                <Button
                  secondary
                  onClick={() => {
                    setModal(false);
                    setModalState();
                  }}
                >
                  No
                </Button>
              </>
            ),
          });
        }}
      >
        <CustomModal
          header={modalState ? modalState.header : null}
          message={modalState ? modalState.message : null}
          setModal={setModal}
          modalState={modal}
          color={modalState ? modalState.color : null}
          clearMessage={() => {
            setModalState();
          }}
          buttons={modalState ? modalState.buttons : null}
        />
        <MainControlsSubContainer>
          <MainControlLeft
            URN_HOLDER={urnHolder}
            urnId={urnId}
            setUrnId={setUrnId}
            adType={adType}
            setAdType={setAdType}
            cms={cms}
            setCms={setCms}
            cols={cols}
            setCols={setCols}
            date={date}
            setDate={setDate}
            publication={publication}
            setPublication={setPublication}
            instructions={instructions}
            setInstructions={setInstructions}
            mono={mono}
            setMono={setMono}
            setModal={setModal}
          />
          <MainControlRight
            PREVIOUS_FILE_STATE={PREVIOUS_FILE_STATE}
            fileState={fileState}
            setFileState={setFileState}
            previousUploads={previousUploads}
            oldFileSelected={oldFileSelected}
            setOldFileSelected={setOldFileSelected}
            NEW_FILE_STATE={NEW_FILE_STATE}
            setFileUpload={setFileUpload}
            setFileUrl={setFileUrl}
            fileUrl={fileUrl}
            setImageType={setImageType}
            imageType={imageType}
          />
        </MainControlsSubContainer>
        <Loading
          loading={loading || uploadPreviousArtworkData.loading}
          content="UPLOADING ARTWORK..."
        />
      </MainControlForm>
    </MainControlsContainer>
  );
};

export default MainControls;
